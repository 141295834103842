import { getOriginDetails } from '../helpers/getOriginDetails';
import { AnalyticsPage } from '../types';

export const trackPage = (pageData: AnalyticsPage) => {
  if ((window as any).utag && (window as any).utag.view)
    (window as any).utag.view({
      page_site_region: 'uk',
      origin_details: getOriginDetails(),
      ...pageData
    });
};

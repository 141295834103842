import { cpsApiUrl } from '../constants';
import { getEnv } from './getEnv';
import { getQuery } from './getQuery';

export const buildWebToWebRequestUrl = (requestUrlEndParts: string) => {
  const applicationGroupId = getQuery('brand');
  const env = getEnv();
  const urlDomain = cpsApiUrl[env];

  return `${urlDomain}/accountlinking/v1/amazon/alexa/tenants/wls/application-groups/${applicationGroupId}_web/${requestUrlEndParts}`;
};

import { Environments } from './enums';

export const environmentVariables = {
  [Environments.DEV]: {
    API_PATH: 'https://join.dev.wireless.radio',
    NEWRELIC_LICENSE_KEY: 'NRBR-cf52fc2ddad70cda727',
    NEWRELIC_APPLICATION_ID: '1386211950',
    NEWRELIC_ACCOUNT_ID: '1082390',
    NEWRELIC_AGENT_ID: '1386211950',
    MY_ACCOUNT_URL: 'https://myaccount.dev.wireless.radio'
  },
  [Environments.STAGING]: {
    API_PATH: 'https://join.staging.wireless.radio',
    NEWRELIC_LICENSE_KEY: 'NRBR-cf52fc2ddad70cda727',
    NEWRELIC_APPLICATION_ID: '1386211989',
    NEWRELIC_ACCOUNT_ID: '1082390',
    NEWRELIC_AGENT_ID: '1386211989',
    MY_ACCOUNT_URL: 'https://myaccount.staging.wireless.radio'
  },
  [Environments.PROD]: {
    API_PATH: 'https://join.wireless.radio',
    NEWRELIC_LICENSE_KEY: 'NRBR-fdd530848b51f172847',
    NEWRELIC_APPLICATION_ID: '1386211938',
    NEWRELIC_ACCOUNT_ID: '695413',
    NEWRELIC_AGENT_ID: '1386211938',
    MY_ACCOUNT_URL: 'https://myaccount.wireless.radio'
  },
  [Environments.LOCAL]: {
    API_PATH: 'http://localhost:4000',
    MY_ACCOUNT_URL: 'https://myaccount.dev.wireless.radio'
  }
};

import { BrandURL } from '../types';
import {
  WEB_URLS,
  talkRadioMobileURL,
  talksportMobileURL,
  talktvMobileURL,
  talktvWebURL,
  virginRadioMobileURL
} from '../constants';
import { Brand } from '../enums';
import { getEnv } from './getEnv';

export const getBrandURL = (brand: Brand): BrandURL => {
  const environment = getEnv();
  const brandURLs: { [key: string]: BrandURL } = {
    [Brand.VIRGINRADIO]: {
      mobile: virginRadioMobileURL,
      web: WEB_URLS[brand][environment]
    },
    [Brand.TALKSPORT]: {
      mobile: talksportMobileURL,
      web: WEB_URLS[brand][environment]
    },
    [Brand.TALKSPORT2]: {
      mobile: talksportMobileURL,
      web: WEB_URLS[brand][environment]
    },
    [Brand.TALKTV]: {
      mobile: talktvMobileURL,
      web: talktvWebURL[environment]
    },
    [Brand.TALKRADIO]: {
      mobile: talkRadioMobileURL,
      web: talktvWebURL[environment]
    }
  };

  return brandURLs[brand];
};

import { SideTextMessages } from '../../constants';
import './SideText.scss';

const SideText = () => {
  return (
    <div className="text">
      <h1>{SideTextMessages.header}</h1>
      <ul>
        {SideTextMessages.listSentences.map((text, index) => (
          <li key={index}>{text}</li>
        ))}
      </ul>
    </div>
  );
};

export { SideText };

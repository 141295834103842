import { Brand } from '../enums';

export const getOriginDetails = () => {
  const queryStringParams = new URLSearchParams(window.location.search);

  const referrerQueryStringParam = 'referrer';
  const mobileQueryStringParam = 'ILC';
  const webQueryStringParam = 'brand';

  const getCaseInsensitiveParam = (param: string) => {
    for (const [key] of queryStringParams) {
      if (key.toLowerCase() === param.toLowerCase()) {
        return key;
      }
    }
    return null;
  };

  const mobileParam = getCaseInsensitiveParam(mobileQueryStringParam);

  let originDetails =
    queryStringParams.get(referrerQueryStringParam) ||
    queryStringParams.get(mobileParam) ||
    queryStringParams.get(webQueryStringParam);

  if (originDetails && originDetails.length) {
    originDetails = `${originDetails.split('-').join(' ')}`;

    if (originDetails !== 'alexa' && !originDetails.includes('app'))
      // e.g.: talksport website
      return `${originDetails} website`;

    // e.g.: alexa || talksport ios app
    return originDetails;
  }

  return `${Brand.TALKSPORT} website`;
};

import './AlexaHeader.scss';

const AlexaHeader = () => {
  return (
    <>
      <header className="alexa-header">
        <img
          className="header__image"
          src="https://assets.wireless.radio/images/alexa/registration/alexa-logo.png"
          alt="Alexa logo"
        />
      </header>
    </>
  );
};

export default AlexaHeader;

export const texts = {
  something_went_wrong: 'Something went wrong',
  error_try_again: 'Oops! Something seems to have gone wrong please try reloading the page.',
  confirm_account_access: 'Confirm Access to your Account',
  confirm_happy_for: 'Please confirm you’re happy for:',
  alexa_account_access: 'Alexa to access to your account.',
  alexa_view_activity:
    'Alexa to view your activity, including what live radio, catch-up shows, and podcasts you have listened to.',
  privacy_policy_description:
    'News Broadcasting to offer personalized ads, according to the preferences you selected in our privacy manager, as explained in our ',
  privacy_policy: 'Privacy Policy',
  change_privacy_preferences: '. You can change your privacy preferences ',
  unlink_alexa_app: 'You can unlink from the alexa app.',
  agree_link_account: 'Agree and Link account',
  cancel: 'Cancel',
  close: 'Close',
  reload: 'Reload',
  alexa_linking_successful: 'Alexa Account Linking Successful',
  alexa_linking_title:
    'You have successfully linked your Amazon account with your News Broadcasting account.',
  alexa_linking_subtitle: 'You can now listen to any of our radio stations on Alexa.',
  alexa_linking_subtitle2: 'Just say “Alexa, play Virgin Radio”.'
};

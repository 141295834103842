import { buildWebToWebRequestUrl } from '../buildWebToWebRequestUrl';
import { getQuery } from '../getQuery';
import { redirectWithParams } from '../redirect/redirectWithParams';

export const redirectToAuthorize = (amazonCode: string) => {
  const redirectAuthUrl = buildWebToWebRequestUrl('account/authorize');
  let params = `amazonAuthCode=${amazonCode}`;

  const state = getQuery('state');

  if (state) {
    try {
      const { extraInfo }: { extraInfo: string } = JSON.parse(atob(state));
      if (extraInfo) params = `${params}&extraInfo=${encodeURIComponent(extraInfo)}`;
    } catch (error) {
      return redirectWithParams(redirectAuthUrl, params);
    }
  }

  return redirectWithParams(redirectAuthUrl, params);
};

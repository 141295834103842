import AlexaHeader from '../../components/AlexaHeader/AlexaHeader';
import './LinkingResultPage.scss';
import { getEnv } from '../../helpers/getEnv';
import { getQuery } from '../../helpers/getQuery';
import { APP_URLS_LINKING_RESULT, WEB_URLS } from '../../constants';
import { redirectWithParams } from '../../helpers/redirect/redirectWithParams';
import { useEffect, useState } from 'react';
import { texts } from '../../i18n/en';
import {
  trackAlexaLinkResultEvent,
  trackAlexaLinkResultPage
} from '../../helpers/analyticsHelpers';
import { useAppContext } from '../../AppProvider';

const LinkingResultPage = () => {
  const { tealiumScriptHasBeenLoaded } = useAppContext();
  const [success, setSuccess] = useState(false);

  const handleCloseButtonClick = () => {
    const environment = getEnv();

    const brand = getQuery('brand');
    const isApp = getQuery('type') === 'app';
    const linkCompleted = getQuery('result') === 'success';
    const extraInfo = getQuery('extraInfo');

    const URLs = isApp ? APP_URLS_LINKING_RESULT : WEB_URLS;

    trackAlexaLinkResultEvent(linkCompleted ? 'back to radio station' : 'reload', linkCompleted);

    if (!brand) return redirectWithParams(WEB_URLS.talksport.prod);

    if (extraInfo) {
      try {
        const decodedExtraInfo = atob(extraInfo); // expected extraInfo to be 'redirectTo=<url>'
        const redirectTo = decodedExtraInfo.split('=')[1];

        if (redirectTo) return redirectWithParams(redirectTo);
      } catch (error) {
        return redirectWithParams(
          URLs[brand][environment],
          `alexaLinkingCompleted=${linkCompleted}`
        );
      }
    }

    return redirectWithParams(URLs[brand][environment], `alexaLinkingCompleted=${linkCompleted}`);
  };

  useEffect(() => {
    try {
      const isSuccess = getQuery('result') === 'success';

      const brand = getQuery('brand');

      if (isSuccess && brand) setSuccess(true);
    } catch (e) {
      console.log('Alexa linking result:', e);
    }
  }, []);

  useEffect(() => {
    if (tealiumScriptHasBeenLoaded) {
      if (success) {
        trackAlexaLinkResultPage(success);
      } else {
        trackAlexaLinkResultPage(false);
      }
    }
  }, [success, tealiumScriptHasBeenLoaded]);

  return (
    <div className="app linking-result-page">
      <AlexaHeader />

      <section id="site" className="site-linking-result">
        <main className="main-linking-result">
          <div id="message_wrapper">
            {success ? (
              <>
                <div id="success_wrapper">
                  <p id="success_title">{texts.alexa_linking_successful}</p>
                  <p className="success_subtitle">{texts.alexa_linking_title}</p>
                  <p className="success_subtitle">{texts.alexa_linking_subtitle}</p>
                  <p className="success_subtitle">{texts.alexa_linking_subtitle2}</p>
                </div>
              </>
            ) : (
              <>
                <div id="error_wrapper">
                  <h1 id="error_title">{texts.error_try_again}</h1>
                </div>
              </>
            )}
          </div>

          <div id="btn_wrapper">
            <button
              id="close_btn"
              className="btn-alexa btn-alexa-primary"
              type="button"
              onClick={handleCloseButtonClick}
            >
              {success ? texts.close : texts.reload}
            </button>
          </div>
        </main>
      </section>
    </div>
  );
};

export default LinkingResultPage;

import { Brand } from '../enums';
import { getQuery } from './getQuery';

export const getBrand = (): Brand => {
  const brand = getQuery('brand');
  if (!brand || brand === Brand.TALKSPORT) {
    return Brand.TALKSPORT;
  }

  return brand as Brand;
};

import { getBrand } from '../getBrand';
import { getBrandURL } from '../getBrandURL';
import { getQuery } from '../getQuery';
import { isWebType } from '../isWebType';

export const redirectToBrand = (params: Record<string, string> = {}, isAbortFlow = false) => {
  const brand = getBrand();
  const brandURL = getBrandURL(brand);
  const webType = isWebType();

  const redirectTo = getQuery('redirectTo');
  if (redirectTo) {
    return redirectToQuery(brandURL.web, redirectTo, params, isAbortFlow);
  }

  if (webType) {
    return redirectWithParams(brandURL.web, params);
  }

  if (isMobilePlatform()) {
    return redirectWithParams(brandURL.mobile, params);
  }

  return redirectWithParams(brandURL.web, params);
};

export const isMobilePlatform = () => {
  const iosUserAgent = /iPhone|iPad|iPod/i;
  const androidUserAgent = /Android/i;

  return !!navigator.userAgent.match(iosUserAgent) || !!navigator.userAgent.match(androidUserAgent);
};

export const redirectWithParams = (url: string, params: Record<string, string> = {}) => {
  const urlParams = Object.entries(params)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join('&');

  window.location.href = urlParams ? `${url}?${urlParams}` : `${url}`;
};

export const redirectToQuery = (brandURL, base64Paths, params, isAbortFlow = false) => {
  try {
    const redirectPaths = atob(base64Paths);
    const { successPath, abortPath } = JSON.parse(redirectPaths);

    const dest = new URL(`${brandURL}${isAbortFlow ? abortPath : successPath}`);

    Object.entries(params).forEach(([key, val]) => dest.searchParams.append(key, val as string));

    window.location.href = dest.href;
  } catch (e) {
    window.location.href = brandURL;
  }
};

import { Environments } from '../enums';
import { environmentVariables } from '../env';

export const getEnv = () => {
  const url = window.location.origin;

  if (url.search(/(local|dev|uat|pr-)/g) >= 0) return 'dev';

  if (url.search(/(staging)/g) >= 0) return 'staging';

  return 'prod';
};

export const getEnvVar = (name: string): any => {
  const env = process.env.REACT_APP_ENV as Environments;
  try {
    return environmentVariables[env][name] || '';
  } catch (error) {
    console.log('getEnvVar:', error?.message);

    return '';
  }
};

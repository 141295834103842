import { headerImg, landingPageMessages } from '../../constants';
import { redirectToBrand } from '../../helpers/redirect/redirectToBrand';
import './GenericError.scss';

const GenericError = () => {
  const handleCloseButton = () => {
    redirectToBrand();
  };

  return (
    <div className="app generic-error">
      <header id="broadcasting-header" className="hidden">
        <img src={headerImg} alt="Banner" />
      </header>
      <section className="site">
        <main className="generic-error-main">
          <div className="title_wrapper">
            <h1 className="greeting">{landingPageMessages.errorHeader}</h1>
          </div>
          <div className="message_wrapper">
            <p className="paragraph">{landingPageMessages.genericError}</p>
          </div>
          <div id="btn_wrapper">
            <div>
              <button
                className="btn-generic btn-generic-primary"
                type="button"
                onClick={handleCloseButton}
              >
                Close
              </button>
            </div>
          </div>
        </main>
      </section>
    </div>
  );
};

export { GenericError };

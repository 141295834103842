import { buildWebToWebRequestUrl } from './buildWebToWebRequestUrl';
import { getQuery } from './getQuery';
import { redirectWithParams } from './redirect/redirectWithParams';

export interface apiEndUrlResponse extends Response {
  appUrl: string;
  lwaUrl: string;
}

export const fetchAmazonLoginUrl = () => {
  const brand = getQuery('brand');
  const extraInfo = getQuery('extraInfo');

  let url = 'urls';
  let failLinkingParams = `result=failure&brand=${brand}`;

  if (extraInfo) {
    const extraInfoUrlEncoded = encodeURIComponent(extraInfo);
    url = `${url}?extraInfo=${extraInfoUrlEncoded}`;
    failLinkingParams = `${failLinkingParams}&extraInfo=${extraInfoUrlEncoded}`;
  }

  const requestUrl = buildWebToWebRequestUrl(url);
  const apiRequest = new Request(requestUrl);

  fetch(apiRequest, { method: 'GET', credentials: 'include' })
    .then((response: apiEndUrlResponse) => response.json())
    .then((res) => {
      if (res?.lwaUrl) return redirectWithParams(res.lwaUrl);

      if (res?.errorCode) failLinkingParams = `${failLinkingParams}&error_code=${res.errorCode}`;

      return redirectWithParams(`${window.location.origin}/linking-result`, failLinkingParams);
    })
    .catch((error) => {
      return redirectWithParams(`${window.location.origin}/linking-result`, failLinkingParams);
    });
};

import {
  customErrorPageMessageHeader,
  customErrorPageMessageParagraph,
  headerImg
} from '../../constants';
import './ErrorPage.scss';
const ErrorPage = () => {
  return (
    <div className="app broadcasting">
      <header className="broadcasting header">
        <img src={headerImg} alt="Banner" />
      </header>
      <section className="site broadcasting">
        <main className="error-main">
          <div className="msg_wrapper">
            <p className="message">{customErrorPageMessageHeader}</p>
            <p className="message">{customErrorPageMessageParagraph}</p>
          </div>
        </main>
      </section>
    </div>
  );
};
export { ErrorPage };

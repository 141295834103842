import { SOFT_STOP_SEEN_COOKIE, CPN_PARAM, verificationUrl, alexaTandCUrl } from '../../constants';
import { getEnvVar } from '../getEnv';
import { getQuery } from '../getQuery';
import { isWebType } from '../isWebType';
import { paramsToObject } from '../paramsToObject';
import { parseCookieValue } from '../parseCookieValue';
import { redirectToBrand, redirectToQuery, redirectWithParams } from './redirectToBrand';

export const applyRedirect = (cpn: string, params = {}) => {
  const softStopSeenCookie = parseCookieValue(SOFT_STOP_SEEN_COOKIE);
  const url = new URL(window.location.href);
  const urlParams = new URLSearchParams(url.search);
  const cpnParam = urlParams.get(CPN_PARAM);

  const referrer = getQuery('referrer');

  if (referrer === 'alexa') {
    return redirectWithParams(
      alexaTandCUrl,
      paramsToObject(new URLSearchParams(window.location.search))
    );
  }

  if (referrer === 'my-account') {
    const redirectTo = getQuery('redirectTo');
    const urlParams = paramsToObject(new URLSearchParams(window.location.search));
    delete urlParams.redirectTo;
    delete urlParams.referrer;

    return redirectToQuery(getEnvVar('MY_ACCOUNT_URL'), redirectTo, urlParams);
  }

  if (!isWebType()) {
    return redirectToBrand(params);
  }

  if (isWebType() && softStopSeenCookie && cpnParam === cpn) {
    redirectToBrand();
  }

  return redirectWithParams(verificationUrl, paramsToObject(urlParams));
};

import { getOriginDetails } from '../helpers/getOriginDetails';
import { AnalyticsEvent } from '../types';

export const trackEvent = (eventData: AnalyticsEvent) => {
  if ((window as any).utag && (window as any).utag.link) {
    (window as any).utag.link({
      origin_details: getOriginDetails(),
      ...eventData
    });
  }
};

export enum VerificationStatus {
  PENDING = 'PENDING',
  NOT_VERIFIED = 'NOT_VERIFIED',
  VERIFIED = 'VERIFIED'
}

export enum Brand {
  VIRGINRADIO = 'virginradio',
  TALKSPORT = 'talksport',
  TALKSPORT2 = 'talksport2',
  TALKTV = 'talktv',
  TALKRADIO = 'talk-radio'
}

export enum Environments {
  DEV = 'dev',
  PROD = 'prod',
  STAGING = 'staging',
  LOCAL = 'local'
}

import { StrictMode } from 'react';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import routes from './routes';
import { AppProvider } from './AppProvider';
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import { getEnvVar } from './helpers/getEnv';

const options = {
  init: {
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: ['bam.nr-data.net'] }
  },
  info: {
    beacon: 'bam.nr-data.net',
    errorBeacon: 'bam.nr-data.net',
    licenseKey: getEnvVar('NEWRELIC_LICENSE_KEY'),
    applicationID: getEnvVar('NEWRELIC_APPLICATION_ID'),
    sa: 1
  },
  loader_config: {
    accountID: getEnvVar('NEWRELIC_ACCOUNT_ID'),
    trustKey: '1022681',
    agentID: getEnvVar('NEWRELIC_AGENT_ID'),
    licenseKey: getEnvVar('NEWRELIC_LICENSE_KEY'),
    applicationID: getEnvVar('NEWRELIC_APPLICATION_ID')
  }
};

new BrowserAgent(options);

const root = createRoot(document.getElementById('root') as any);
const router = createBrowserRouter(routes);

root.render(
  <StrictMode>
    <AppProvider>
      <RouterProvider router={router} />
    </AppProvider>
  </StrictMode>
);

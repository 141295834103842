import { trackEvent } from '../analytics/trackEvent';
import { getTealiumArguments } from './getTealiumArguments';
import { trackPage } from '../analytics/trackPage';
import { Verification } from '../types';
import { VerificationStatus } from '../enums';
import { eventActions, eventBrowsingMethods, eventNames, registrationPage } from '../constants';
import { paramsToObject } from './paramsToObject';
import { getItemFromLocalStorage } from './email-verification/saveItemToLocalStorage';

export const trackThankYouPage = (cpn: string) => {
  const pageEvent = getThankYouPageParams(cpn);

  trackPage(pageEvent);
};

const getThankYouPageParams = (cpn: string) => ({
  page_name: 'email verification page',
  page_section: 'registration',
  page_type: 'email verification page',
  page_restrictions: 'restricted',
  customer_id: `CPN:${cpn}`
});

const getOkGotItNavigationEvent = (cpn: string, userUpdatedEmail?: boolean) => {
  const tealiumArguments = getTealiumArguments();
  const pageEvents = getRegistrationPageEvents(
    userUpdatedEmail ? registrationPage.pleaseCheckInboxPageName : registrationPage.signUpPageName,
    registrationPage.pageType,
    cpn
  );
  if (tealiumArguments) {
    return prepareMobileTealium(tealiumArguments);
  }
  return {
    event_navigation_name: userUpdatedEmail ? eventNames.pleaseCheckInbox : eventNames.okGotIt,
    event_navigation_action: eventActions.navigation,
    event_navigation_browsing_method: eventBrowsingMethods.click,
    ...pageEvents
  };
};

export const trackOkGotItButtonClick = (cpn: string, userUpdatedEmail: boolean) => {
  const navigationEvent = getOkGotItNavigationEvent(cpn, userUpdatedEmail);

  trackEvent(navigationEvent);
};

export const trackEmailVerificationSoftStopPageView = () => {
  const pageName = "you're signed up page";
  const pageType = 'registration page';
  const user = getItemFromLocalStorage('user');
  const user_cpn = user?.cpn;

  trackVerification(pageName, pageType, user_cpn);
};

export const trackEmailVerificationHardStopPageView = () => {
  const pageName = 'please verify your email page';
  const pageType = 'registration page';
  const user = getItemFromLocalStorage('user');
  const user_cpn = user?.cpn;

  trackVerification(pageName, pageType, user_cpn);
};

const mapEmailVerificationStatusToUtagValue = (emailVerificationStatus: VerificationStatus) => {
  const emailVerificationStatusPageView = {
    [VerificationStatus.NOT_VERIFIED]: 'not verified',
    [VerificationStatus.PENDING]: 'pending',
    [VerificationStatus.VERIFIED]: 'verified'
  };

  return emailVerificationStatusPageView[emailVerificationStatus];
};

export const trackCheckYourInboxPageView = () => {
  const user = getItemFromLocalStorage('user');
  const status = getItemFromLocalStorage('status');
  const emailVerificationStatusValue =
    status?.verificationStatus && mapEmailVerificationStatusToUtagValue(status?.verificationStatus);

  const pageView = {
    page_name: 'please check your inbox page',
    page_section: 'registration',
    page_type: 'registration page',
    page_site_region: 'uk',
    page_restrictions: 'restricted',
    customer_id: user?.cpn ? `CPN:${user.cpn}` : null,
    email_verification_status: emailVerificationStatusValue || null
  };

  trackPage(pageView);
};

const getRegistrationPageEvents = (pageName: string, pageType: string, cpn: string) => ({
  page_name: pageName,
  page_section: 'registration',
  page_type: pageType,
  customer_id: `CPN:${cpn}`
});

const getEmailVerificationPageView = (pageName: string, pageType: string, cpn: string) => ({
  page_name: pageName,
  page_section: 'registration',
  page_type: pageType,
  page_restrictions: 'restricted',
  customer_id: `CPN:${cpn}`
});

const handleWebVerification = (pageName: string, pageType: string, cpn: string) => {
  const pageView = getEmailVerificationPageView(pageName, pageType, cpn);

  trackPage(pageView);
};

const handleMobileVerification = (args) => {
  const tealiumArguments = prepareMobileTealium(args);

  trackEvent(prepareMobileTealium(tealiumArguments));
};

const prepareMobileTealium = (tealiumArguments) => {
  const { profile, ...restTealiumArgs } = tealiumArguments;
  return restTealiumArgs;
};

const trackVerification = (pageName: string, pageType: string, cpn: string) => {
  const tealiumArguments = getTealiumArguments();

  if (tealiumArguments) {
    return handleMobileVerification(tealiumArguments);
  }

  return handleWebVerification(pageName, pageType, cpn);
};

export const trackAlexaTCPage = () => {
  const pageData =
    getTargs() ||
    getAlexaPageTrackingData('alexa t&cs', 'terms and conditions', 'permissions page');

  trackPage(pageData);
};

const getTargs = () => {
  const { targs } = paramsToObject(new URLSearchParams(window.location.search));

  try {
    return JSON.parse(atob(targs));
  } catch (error) {
    return null;
  }
};

export const getAlexaPageTrackingData = (
  page_name: string,
  page_section: string,
  page_type: string
) => {
  const user = getItemFromLocalStorage('user');

  return {
    page_name,
    page_section,
    page_type,
    page_site_region: 'uk',
    page_restrictions: 'restricted',
    customer_id: user?.cpn ? `CPN:${user.cpn}` : null
  };
};

export const trackAlexaTCEvent = (eventName: string) => {
  const eventData = {
    event_navigation_action: 'navigation',
    event_navigation_name: `button: amazon alexa: ${eventName}`,
    event_navigation_browsing_method: 'click',
    ...(getTargs() ||
      getAlexaPageTrackingData('alexa t&cs', 'terms and conditions', 'permissions page'))
  };

  trackEvent(eventData);
};

export const trackAlexaLinkResultPage = (success: boolean) => {
  const pageData = getAlexaPageTrackingData(
    `alexa account linking ${success ? 'successful' : 'failed'}`,
    'terms and conditions',
    'permissions page'
  );

  trackPage(pageData);
};

export const trackAlexaLinkResultEvent = (eventName: string, success: boolean) => {
  const eventData = {
    event_navigation_action: 'navigation',
    event_navigation_name: `button: amazon alexa: ${eventName}`,
    event_navigation_browsing_method: 'click',
    ...getAlexaPageTrackingData(
      `alexa account linking ${success ? 'successful' : 'failed'}`,
      'terms and conditions',
      'permissions page'
    )
  };

  trackEvent(eventData);
};

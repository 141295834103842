export const landingPageMessages = {
  thankYouHeader: 'Thank You!',
  thankYou:
    "Your email address has been verified and your account has been activated. You can close this window and access our station's apps and websites now.",
  alreadyVerifiedHeader: "You've already verified",
  alreadyVerified:
    "It looks like you've already verified. You can close this window and access our station's apps and websites now.",
  errorHeader: 'Something went wrong',
  genericError:
    "Unfortunately we couldn't verify your email address at this time. Please try again later.",
  noConnectionError: 'An error occurred, please try again.'
};

export const SideTextMessages = {
  header: 'One login across all devices to:',
  listSentences: [
    'Listen to your favourite radio stations',
    'Enjoy exclusive video content',
    'Catch up with on-demand content and podcasts',
    'Be in-the-know with the latest news'
  ]
};

export const emailVerificationPageMessages = {
  signedUpHeader: "You're signed up!",
  verifyEmailHeader: 'Please verify your email',
  checkInboxHeader: 'Please check your inbox',
  thanksForRegisteringMessage:
    'Thanks for registering. A verification email has been sent to the following address',
  verifyEmailMessage: 'A verification email has been sent to the following address',
  inboxMessage: "We've sent a new verification email to",
  selectLinkMessage: 'Please select the link in the email to verify your account.',
  genericErrorMessage: "We'll get your email verified next time.",
  softStopMessage:
    "You can browse today but will need to click on the link in the email to activate your account and access our station's apps and websites in the future.",
  hardStopMessage:
    "You will have to verify your email in order to continue browsing and accessing our stations' apps and websites.",
  cantFindEmail: "Can't find the email?",
  checkSpam: 'Check your spam folder or resend it',
  notCorrectEmail: 'Not the correct email address?',
  updateYourAccountDetails: 'Update your account details below'
};

export const profilePageMessages = {
  welcome: 'Welcome, ',
  done: 'Done',
  infoMessage:
    'To ensure we’re able to offer you a personalised experience we require the following details.',
  requiredFields: 'These fields are required',
  yob: 'Year of birth',
  selectYear: 'Select year',
  info: 'Info',
  iconAgeMessage: 'Knowing your age will help us serve audio adverts better suited for you.',
  iconGenderMessage: ' Knowing your gender will help us serve audio adverts better suited for you.',
  gender: 'Gender',
  selectGender: 'Select gender'
};

export const customErrorPageMessageHeader =
  'Unfortunately, we were unable to complete your request.';
export const customErrorPageMessageParagraph = 'Please try again later';

export const tokenErrorCodes = [
  'EMAIL-VER-20200',
  'EMAIL-VER-20300',
  'EMAIL-VER-20400',
  'ERR-10100'
];

export const networkErrorCode = 'ERR-999';
export const alreadyVerifiedErrorCode = 'EMAIL-VER-20100';

export const SSO_COOKIE = 'acs_wls';
export const SOFT_STOP_SEEN_COOKIE = 'wls_soft_page_seen';
export const CPN_PARAM = 'eid';

export const PRE_PROD_GRACE_PERIOD_IN_MIN = 1440; //24h
export const PROD_GRACE_PERIOD_IN_MIN = 259200; //180days in min

export const headerImg =
  'https://assets.wireless.radio/images/broadcasting/sign-in-form-header.png';

export const profileSuccessMessage = 'Profile updated successfully';
export const verificationUrl = '/verification';

export const cpsApiUrl = {
  dev: 'https://dev.login.wireless.radio',
  staging: 'https://login.staging.wireless.radio',
  prod: 'https://login.wireless.radio'
};

export const WEB_URLS = {
  virginradio: {
    dev: 'https://dev.virginradio.co.uk',
    staging: 'https://staging.virginradio.co.uk',
    prod: 'https://virginradio.co.uk'
  },
  talktv: {
    dev: 'https://dev.talk.tv',
    staging: 'https://staging.talk.tv',
    prod: 'https://talk.tv'
  },
  'talk-radio': {
    dev: 'https://dev.talk.tv',
    staging: 'https://staging.talk.tv',
    prod: 'https://talk.tv'
  },
  talksport: {
    dev: 'https://www-dev.uat-talksport.com',
    staging: 'https://staging-talksport.com',
    prod: 'https://talksport.com'
  },
  talksport2: {
    dev: 'https://www-dev.uat-talksport.com/play/talksport2',
    staging: 'https://staging-talksport.com/play/talksport2',
    prod: 'https://talksport.com/play/talksport2'
  }
};

export const APP_URLS = {
  virginradio: {
    dev: 'vrapp://app.dev.virginradio.co.uk/home',
    staging: 'vrapp://app.dev.virginradio.co.uk/home',
    prod: 'vrapp://app.virginradio.co.uk/home'
  },
  talkradio: {
    dev: 'talkradio://app.dev.talk.tv/home',
    staging: 'talkradio://app.dev.talk.tv/home',
    prod: 'talkradio://app.talk.tv/home'
  },
  talksport: {
    dev: 'tsapp://app.dev.talksport.com/home',
    staging: 'tsapp://app.dev.talksport.com/home',
    prod: 'tsapp://app.talksport.com/home'
  },
  timesradio: {
    dev: 'timesradio://app.dev.times.radio/home',
    staging: 'timesradio://app.dev.times.radio/home',
    prod: 'timesradio://app.times.radio/home'
  },
  talktv: {
    dev: 'ttvapp://app.dev.talk.tv/home',
    staging: 'ttvapp://app.staging.talk.tv/home',
    prod: 'ttvapp://app.talk.tv/home'
  }
};

export const APP_URLS_LINKING_RESULT = {
  virginradio: {
    dev: 'https://app.dev.virginradio.co.uk/settings/alexa',
    staging: 'https://app.dev.virginradio.co.uk/settings/alexa',
    prod: 'https://app.virginradio.co.uk/settings/alexa'
  },
  'talk-radio': {
    dev: 'https://app.dev.talk.tv/settings/alexa',
    staging: 'https://app.dev.talk.tv/settings/alexa',
    prod: 'https://app.talk.tv/settings/alexa'
  },
  talksport: {
    dev: 'https://app.dev.talksport.com/settings/alexa',
    staging: 'https://app.dev.talksport.com/settings/alexa',
    prod: 'https://app.talksport.com/settings/alexa'
  },
  timesradio: {
    dev: 'https://app.dev.times.radio/settings/alexa',
    staging: 'https://app.dev.times.radio/settings/alexa',
    prod: 'https://app.times.radio/settings/alexa'
  }
};

export const talktvWebURL = {
  dev: 'https://dev.talk.tv',
  staging: 'https://staging.talk.tv',
  prod: 'https://talk.tv'
};

export const acceptConsentUrl = {
  dev: 'https://account.uat.wireless.radio/authorize',
  staging: 'https://account.staging.wireless.radio/authorize',
  prod: 'https://account.wireless.radio/authorize'
};

export const clientId = {
  dev: 'aVFu3pmv6r8G4Nb0k3j40T7UoN6E9nVJ',
  staging: 'VGmCwKrcugXCXQMPAMTnfA8fbA7HelPD',
  prod: 'ndkXws1DnsCkNRoB1bEEm9IOkIrwMmqx'
};

export const pmId = {
  gdpr: '769353',
  ccpa: '769354'
};

export const cookieName = 'acs_wls';
export const cpnParam = 'eid';

export const consentTriggerApiScript =
  'function _typeof(t){return(_typeof="function"==typeof Symbol&&"symbol"==typeof Symbol.iterator?function(t){return typeof t}:function(t){return t&&"function"==typeof Symbol&&t.constructor===Symbol&&t!==Symbol.prototype?"symbol":typeof t})(t)}!function(){for(var t,e,o=[],n=window,r=n;r;){try{if(r.frames.__tcfapiLocator){t=r;break}}catch(t){}if(r===n.top)break;r=n.parent}t||(function t(){var e=n.document,o=!!n.frames.__tcfapiLocator;if(!o)if(e.body){var r=e.createElement("iframe");r.style.cssText="display:none",r.name="__tcfapiLocator",e.body.appendChild(r)}else setTimeout(t,5);return!o}(),n.__tcfapi=function(){for(var t=arguments.length,n=new Array(t),r=0;r<t;r++)n[r]=arguments[r];if(!n.length)return o;"setGdprApplies"===n[0]?n.length>3&&2===parseInt(n[1],10)&&"boolean"==typeof n[3]&&(e=n[3],"function"==typeof n[2]&&n[2]("set",!0)):"ping"===n[0]?"function"==typeof n[2]&&n[2]({gdprApplies:e,cmpLoaded:!1,cmpStatus:"stub"}):o.push(n)},n.addEventListener("message",(function(t){var e="string"==typeof t.data,o={};if(e)try{o=JSON.parse(t.data)}catch(t){}else o=t.data;var n="object"===_typeof(o)?o.__tcfapiCall:null;n&&window.__tcfapi(n.command,n.version,(function(o,r){var a={__tcfapiReturn:{returnValue:o,success:r,callId:n.callId}};t&&t.source&&t.source.postMessage&&t.source.postMessage(e?JSON.stringify(a):a,"*")}),n.parameter)}),!1))}();';
export const consentTriggerScript =
  '(function () { var e = false; var c = window; var t = document; function r() { if (!c.frames["__uspapiLocator"]) { if (t.body) { var a = t.body; var e = t.createElement("iframe"); e.style.cssText = "display:none"; e.name = "__uspapiLocator"; a.appendChild(e) } else { setTimeout(r, 5) } } } r(); function p() { var a = arguments; __uspapi.a = __uspapi.a || []; if (!a.length) { return __uspapi.a } else if (a[0] === "ping") { a[2]({ gdprAppliesGlobally: e, cmpLoaded: false }, true) } else { __uspapi.a.push([].slice.apply(a)) } } function l(t) { var r = typeof t.data === "string"; try { var a = r ? JSON.parse(t.data) : t.data; if (a.__cmpCall) { var n = a.__cmpCall; c.__uspapi(n.command, n.parameter, function (a, e) { var c = { __cmpReturn: { returnValue: a, success: e, callId: n.callId } }; t.source.postMessage(r ? JSON.stringify(c) : c, "*") }) } } catch (a) { } } if (typeof __uspapi !== "function") { c.__uspapi = p; __uspapi.msgHandler = l; c.addEventListener("message", l, false) } })();';
export const consentUniScript =
  'https://cdn.privacy-mgmt.com/unified/wrapperMessagingWithoutDetection.js';

export const TEALIUM = {
  ACCOUNT_ID: 'newsinternational',
  PROFILE_ID: 'nb.generic'
};

export const eventNames = {
  fullyRegistered: 'registration step2 complete',
  okGotIt: "you're signed up : ok",
  pleaseCheckInbox: 'please check your inbox : ok'
};

export const eventActions = {
  navigation: 'navigation'
};

export const eventBrowsingMethods = {
  click: 'click'
};

export const registrationPage = {
  signUpPageName: "you're signed up page",
  pageType: 'registration page',
  pleaseCheckInboxPageName: 'please check your inbox',
  signUpStep2PageName: 'registration page : step 2'
};

export const alexaTandCUrl = '/alexa';
export const talksportMobileURL = 'tsapp://talksport.com/home';
export const virginRadioMobileURL = 'vrapp://virginradio.co.uk/home';
export const talktvMobileURL = 'ttvapp://talk.tv/home';
export const talkRadioMobileURL = 'talkradio://app.talk.tv/home';

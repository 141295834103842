import { SideText } from '../../components/SideText/SideText';
import { useEffect, useState } from 'react';
import './ProfilePage.scss';
import { getCookie } from '../../helpers/getCookie';
import { SSO_COOKIE, profilePageMessages, profileSuccessMessage } from '../../constants';
import { redirectToBrand } from '../../helpers/redirect/redirectToBrand';
import { fetchProfile, fetchUser } from '../../helpers/fetcher/fetcher';
import { User } from '../../types';
import { applyRedirect } from '../../helpers/redirect/applyRedirect';
import { trackPage } from '../../analytics/trackPage';
import { trackEvent } from '../../analytics/trackEvent';
import { useAppContext } from '../../AppProvider';

const ProfilePage = () => {
  const { tealiumScriptHasBeenLoaded } = useAppContext();
  const [userData, setUserData] = useState<User>({} as User);
  const [showInfo, setShowInfo] = useState(null);
  const [selectYOB, setSelectYOB] = useState('');
  const [selectGender, setSelectGender] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [userSSOCookie, setUserSSOCookie] = useState('');
  const [userCPN, setUserCPN] = useState('');
  const [hidePage, setHidePage] = useState(true);
  const [userFirstName, setUserFirstName] = useState('');

  const currentYear = new Date().getFullYear();
  const firstOption = 1906;
  const lastOption = currentYear - 18;
  const allYears = [];

  for (let year = lastOption; year >= firstOption; year--) {
    allYears.push(year);
  }

  const isButtonDisabled = selectYOB === '' && selectGender === '';

  useEffect(() => {
    const ssoCookieValue = getCookie(SSO_COOKIE);
    ssoCookieValue ? setUserSSOCookie(ssoCookieValue) : redirectToBrand();

    const cpnValue = ssoCookieValue?.split('eid=')[1]?.split('&e=')[0];
    cpnValue ? setUserCPN(cpnValue) : redirectToBrand();
  }, []);

  useEffect(() => {
    if (tealiumScriptHasBeenLoaded) {
      const analyticsPage = {
        page_name: 'registration page : step 2',
        page_section: 'registration',
        page_type: 'registration page',
        page_restrictions: 'public',
        customer_id: `CPN:${userCPN}`
      };

      trackPage(analyticsPage);
    }
  }, [userCPN, tealiumScriptHasBeenLoaded]);

  useEffect(() => {
    if (userSSOCookie && userCPN) {
      const userNameValues = userSSOCookie.split('a=')[1].split('&u=')[0].trim();
      const decodedValues = atob(userNameValues).split(' ');
      const firstName = decodedValues[0].charAt(0).toUpperCase() + decodedValues[0].slice(1);
      setUserFirstName(firstName);

      const getUserData = async () => {
        try {
          const data = await fetchUser(userCPN, userSSOCookie);
          if (data.responseStatus === 200) {
            applyRedirect(userCPN);
          } else {
            setHidePage(false);
            setUserData(data);
          }
        } catch (error: any) {
          redirectToBrand();
        }
      };
      getUserData();
    }
  }, [userSSOCookie, userCPN]);

  useEffect(() => {
    setIsFormValid(selectYOB !== '' && selectGender !== '');
  }, [selectGender, selectYOB]);

  const handleCloseBtn = () => {
    redirectToBrand();
  };

  const handleInfoBtn = (id: string) => {
    if (showInfo === id) {
      setShowInfo(null);
    } else {
      setShowInfo(id);
    }
  };

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();

    if (isFormValid && userData && userCPN && userSSOCookie) {
      try {
        const response = await fetchProfile(userSSOCookie, userCPN, selectGender, selectYOB);

        if (response.message === profileSuccessMessage && tealiumScriptHasBeenLoaded) {
          const analyticsEvent = {
            event_navigation_action: 'navigation',
            event_navigation_name: 'registration step2 complete',
            event_navigation_browsing_method: 'automated',
            page_name: 'registration page : step 2',
            page_section: 'registration',
            page_type: 'registration page',
            customer_id: `CPN:${userCPN}`
          };
          trackEvent(analyticsEvent);
          applyRedirect(userCPN, { message: 'welcomeAlert' });
        }
      } catch (error: any) {
        redirectToBrand();
      }
    }
  };

  return (
    <>
      <div id="app-broadcasting" className={hidePage ? 'hidden' : 'app-profile'}>
        <div className="background"></div>
        <div className="contentWrapper">
          <div className="close_button_wrapper">
            <button className="close_button" onClick={handleCloseBtn}>
              X
            </button>
          </div>
          <div className="form-section">
            <div className="form-wrapper">
              <header className="header">
                <section className="banner"></section>
              </header>
              <section className="profile-site">
                <main className="main">
                  <section className="title">
                    <p className="title-message">
                      {profilePageMessages.welcome} {userFirstName}
                    </p>
                    <p className="info-message">{profilePageMessages.infoMessage}</p>
                  </section>
                  <form
                    id="form"
                    aria-label="form"
                    className="form"
                    onSubmit={handleFormSubmit}
                    noValidate
                  >
                    <fieldset className="fieldset">
                      <p className="fieldset__info">
                        <sup>*</sup>
                        {profilePageMessages.requiredFields}
                      </p>
                      <div
                        className={
                          showInfo === 'selectInfoYears'
                            ? `select__block select__block--active`
                            : `select__block`
                        }
                      >
                        <label className="select__label" htmlFor="selectYear">
                          {profilePageMessages.yob}
                          <sup>*</sup>
                        </label>
                        <div className="select__wrap">
                          <select
                            className="select"
                            name="selectYear"
                            id="selectYear"
                            onChange={(e) => setSelectYOB(e.target.value)}
                            required
                            value={selectYOB}
                          >
                            <option value="">{profilePageMessages.selectYear}</option>
                            {allYears.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                          <button
                            type="button"
                            id="selectInfoYears"
                            className="select__button-info"
                            onClick={() => handleInfoBtn('selectInfoYears')}
                          >
                            <span>{profilePageMessages.info}</span>
                            <svg
                              preserveAspectRatio="xMidYMid"
                              viewBox="-2 -2 35 35"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                            >
                              <path
                                d="M15 12a1 1 0 0 1 1 1v9a1 1 0 0 1-2 0v-9a1 1 0 0 1 1-1z"
                                fill="#000000"
                                mask="url(#b)"
                                transform="matrix(1 0 0 -1 0 35)"
                              />
                              <circle
                                fill="#000000"
                                transform="matrix(1 0 0 -1 0 17)"
                                cx="15"
                                cy="8.5"
                                r="1.5"
                              />
                            </svg>
                          </button>
                        </div>
                        <div className="select__info">
                          <p className="select__info-p">{profilePageMessages.iconAgeMessage}</p>
                        </div>
                      </div>
                      <div
                        className={
                          showInfo === 'selectInfoGender'
                            ? `select__block select__block--active`
                            : `select__block`
                        }
                      >
                        <label className="select__label" htmlFor="selectGender">
                          {profilePageMessages.gender}
                          <sup>*</sup>{' '}
                        </label>
                        <div className="select__wrap">
                          <select
                            className="select"
                            name="selectGender"
                            id="selectGender"
                            onChange={(e) => setSelectGender(e.target.value)}
                            required
                            value={selectGender}
                          >
                            <option value="">{profilePageMessages.selectGender}</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="non-binary">Non binary</option>
                          </select>
                          <button
                            type="button"
                            id="selectInfoGender"
                            className="select__button-info"
                            onClick={() => handleInfoBtn('selectInfoGender')}
                          >
                            <span>{profilePageMessages.info}</span>
                            <svg
                              preserveAspectRatio="xMidYMid"
                              viewBox="-2 -2 35 35"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                            >
                              <path
                                d="M15 12a1 1 0 0 1 1 1v9a1 1 0 0 1-2 0v-9a1 1 0 0 1 1-1z"
                                fill="#000000"
                                mask="url(#b)"
                                transform="matrix(1 0 0 -1 0 35)"
                              />
                              <circle
                                fill="#000000"
                                transform="matrix(1 0 0 -1 0 17)"
                                cx="15"
                                cy="8.5"
                                r="1.5"
                              />
                            </svg>
                          </button>
                        </div>
                        <div className="select__info">
                          <p className="select__info-p">{profilePageMessages.iconGenderMessage}</p>
                        </div>
                      </div>
                    </fieldset>
                    <button
                      id="submit"
                      className="submit"
                      type="submit"
                      disabled={isButtonDisabled}
                    >
                      {profilePageMessages.done}
                    </button>
                  </form>
                </main>
              </section>
            </div>
          </div>

          <SideText />
        </div>
      </div>
    </>
  );
};

export default ProfilePage;

import { HeadScripts } from '../types';

export const appendHeadScripts = (scripts: HeadScripts) => {
  const head = document.getElementsByTagName('head');
  scripts.forEach((scriptData: { src: string } | string) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';

    if (typeof scriptData === 'object' && scriptData.src) {
      script.src = scriptData.src;
    }
    if (typeof scriptData === 'string') {
      script.innerHTML = scriptData;
    }

    document.head.appendChild(script);
  });
};

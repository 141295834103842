import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  SSO_COOKIE,
  alreadyVerifiedErrorCode,
  headerImg,
  landingPageMessages,
  networkErrorCode,
  tokenErrorCodes
} from '../../constants';
import { VerificationStatus } from '../../enums';
import { redirectToBrand } from '../../helpers/redirect/redirectToBrand';
import { VerificationError } from '../../types';
import { fetchVerifyUser } from '../../helpers/fetcher/fetcher';
import './LandingPage.scss';
import { useAppContext } from '../../AppProvider';
import { getCookie } from '../../helpers/getCookie';
import { trackThankYouPage } from '../../helpers/analyticsHelpers';

const LandingPage = () => {
  const { tealiumScriptHasBeenLoaded } = useAppContext();
  const [showThankYouPage, setShowThankYouPage] = useState(false);
  const [showAlreadyVerifiedPage, setShowAlreadyVerifiedPage] = useState(false);
  const [showGenericErrorPage, setShowGenericErrorPage] = useState(false);
  const [showNetworkErrorPage, setShowNetworkErrorPage] = useState(false);
  const [cpn, setCpn] = useState('');
  const { token } = useParams();

  useEffect(() => {
    fetchVerifyUser(token).then((res) =>
      verifyResponse(res as VerificationError | VerificationStatus)
    );
  }, [token]);

  useEffect(() => {
    const cpnValue = getCookie(SSO_COOKIE)?.split('eid=')[1]?.split('&e=')[0];
    cpnValue ? setCpn(cpnValue) : '';
  }, []);

  const verifyResponse = (resp) => {
    if (Object.keys(resp).length === 0) {
      setShowGenericErrorPage(true);
    } else if (resp.verificationStatus === VerificationStatus.VERIFIED) {
      setShowThankYouPage(true);
    } else if (tokenErrorCodes.includes(resp.errorCode)) {
      setShowGenericErrorPage(true);
    } else if (resp.errorCode === alreadyVerifiedErrorCode) {
      setShowAlreadyVerifiedPage(true);
    } else if (resp.errorCode === networkErrorCode) {
      setShowNetworkErrorPage(true);
    } else {
      setShowGenericErrorPage(true);
    }
  };

  useEffect(() => {
    if (showThankYouPage && tealiumScriptHasBeenLoaded) {
      trackThankYouPage(cpn);
    }
  }, [cpn, showThankYouPage, tealiumScriptHasBeenLoaded]);

  const handleCloseButton = () => {
    redirectToBrand();
  };

  const handleRefreshButton = () => {
    window.location.reload();
  };

  return (
    <div className={showThankYouPage ? `app broadcasting` : `app`}>
      <header
        id="broadcasting-header"
        className={showThankYouPage ? `broadcasting header` : `hidden`}
      >
        <img src={headerImg} alt="Banner" />
      </header>
      <section className={showThankYouPage ? `site broadcasting` : `site`}>
        <main className="main">
          <div className="title_wrapper">
            <h1 className="greeting">
              {showThankYouPage && landingPageMessages.thankYouHeader}
              {showAlreadyVerifiedPage && landingPageMessages.alreadyVerifiedHeader}
              {(showGenericErrorPage || showNetworkErrorPage) && landingPageMessages.errorHeader}
            </h1>
          </div>
          <div className="message_wrapper">
            <p className="paragraph">
              {showThankYouPage && landingPageMessages.thankYou}
              {showAlreadyVerifiedPage && landingPageMessages.alreadyVerified}
              {showGenericErrorPage && landingPageMessages.genericError}
              {showNetworkErrorPage && landingPageMessages.noConnectionError}
            </p>
          </div>
          {(showGenericErrorPage || showNetworkErrorPage) && (
            <div id="btn_wrapper">
              {showGenericErrorPage && (
                <div>
                  <button
                    className="btn-error btn-error-primary"
                    type="button"
                    onClick={handleCloseButton}
                  >
                    Close
                  </button>
                </div>
              )}
              {showNetworkErrorPage && (
                <div>
                  <button
                    className="btn-error btn-error-primary"
                    type="button"
                    onClick={handleRefreshButton}
                  >
                    Refresh
                  </button>
                </div>
              )}
            </div>
          )}
        </main>
      </section>
    </div>
  );
};

export { LandingPage };

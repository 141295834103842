import { TealiumArguments } from 'src/types';

export const getTealiumArguments = (): TealiumArguments | null => {
  const queryStringParams = new URLSearchParams(window.location.search);

  const tealiumArgumentsQueryStringParameter = 'targs';
  const hasTealiumArguments = queryStringParams.has(tealiumArgumentsQueryStringParameter);

  if (!hasTealiumArguments) {
    return null;
  }

  const tealiumArgumentsBase64Encoded = queryStringParams.get(tealiumArgumentsQueryStringParameter);

  try {
    return JSON.parse(atob(tealiumArgumentsBase64Encoded || ''));
  } catch (error) {
    return null;
  }
};

import './styles/index.scss';
import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import { getEnv } from '../src/helpers/getEnv';
import { TEALIUM } from './constants';
import { useAppContext } from './AppProvider';

const { ACCOUNT_ID, PROFILE_ID } = TEALIUM;

const App = () => {
  const { setTealiumScriptHasBeenLoaded } = useAppContext();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `//tags.tiqcdn.com/utag/${ACCOUNT_ID}/${PROFILE_ID}/${getEnv()}/utag.js`;
    script.async = true;
    script.type = 'text/javascript';
    const element = document.getElementsByTagName('script')[0];
    element?.parentNode.insertBefore(script, element);
    script.onload = () => setTealiumScriptHasBeenLoaded(true);

    return () => {
      document.head.removeChild(script);
    };
  }, [setTealiumScriptHasBeenLoaded]);

  return <Outlet />;
};

export default App;

import './AlexaPage.scss';
import { paramsToObject } from '../../helpers/paramsToObject';
import { redirectWithParams } from '../../helpers/redirect/redirectWithParams';
import {
  APP_URLS,
  WEB_URLS,
  acceptConsentUrl,
  clientId,
  cookieName,
  cpnParam,
  pmId
} from '../../constants';
import { getEnv } from '../../helpers/getEnv';
import AlexaHeader from '../../components/AlexaHeader/AlexaHeader';
import { useEffect, useState } from 'react';
import { getQuery } from '../../helpers/getQuery';
import { redirectToAuthorize } from '../../helpers/redirect/redirectToAuthorize';
import { fetchAmazonLoginUrl } from '../../helpers/fetchAmazonLoginUrl';
import Loader from '../../components/Loader/Loader';
import { parseCookieValue } from '../../helpers/parseCookieValue';
import { texts } from '../../i18n/en';
import { addConsentCookie, initSPConsent } from '../../helpers/consent';
import { useAppContext } from '../../AppProvider';
import { trackAlexaTCEvent, trackAlexaTCPage } from '../../helpers/analyticsHelpers';

export class ACSCookie {
  value: string;
  private params: URLSearchParams;

  constructor(name: string) {
    this.value = parseCookieValue(name);
    this.params = new URLSearchParams(this.value);
  }

  getParam(name: string) {
    return this.params.get(name);
  }
}

const AlexaPage = () => {
  const { tealiumScriptHasBeenLoaded } = useAppContext();
  const handleAccountLinkCancelButtonClick = (eventName: string) => {
    trackAlexaTCEvent(eventName);

    const { state, redirect_uri } = paramsToObject(new URLSearchParams(window.location.search));

    if (!state || !redirect_uri) return redirectWithParams(WEB_URLS.talksport.prod);

    if (redirect_uri.includes('account/callback')) {
      // case for web and app
      try {
        const environment = getEnv();
        const { extraInfo, applicationGroupId }: { extraInfo: string; applicationGroupId: string } =
          JSON.parse(atob(state));

        if (extraInfo) {
          const decodedExtraInfo = atob(extraInfo); // expected extraInfo to be 'redirectTo=<url>'
          const redirectTo = decodedExtraInfo.split('=')[1];

          if (redirectTo) return redirectWithParams(redirectTo);
        }

        if (applicationGroupId) {
          const [brand, type] = applicationGroupId.split('_');
          const URLs = type === 'app' ? APP_URLS : WEB_URLS;

          return redirectWithParams(URLs[brand][environment], 'alexaLinkingCompleted=false');
        }

        return redirectWithParams(WEB_URLS.talksport.prod);
      } catch (e) {
        return redirectWithParams(WEB_URLS.talksport.prod);
      }
    } else {
      // case for alexa app
      return redirectWithParams(redirect_uri, `state=${state}`);
    }
  };

  const handleChangePreferencesButtonClick = () => {
    trackAlexaTCEvent('change your privacy preferences');

    const { gdpr, ccpa } = (window as any)._sp_;

    try {
      if (window['__tcfapi'] && typeof window['__tcfapi'] === 'function') {
        window['__tcfapi']('getTCData', 2, (data: { gdprApplies: boolean }) => {
          if (data?.gdprApplies && gdpr) gdpr.loadPrivacyManagerModal(pmId.gdpr);
        });
      }

      if (window['__uspapi'] && typeof window['__uspapi'] === 'function') {
        window['__uspapi']('getCustomVendorRejects', 1, (data: { ccpaApplies: boolean }) => {
          if (data?.ccpaApplies && ccpa) ccpa.loadPrivacyManagerModal(pmId.ccpa);
        });
      }
    } catch (e) {
      console.warn(
        'Error occurred attempting to open privacy manager modal. Is Sourcepoint CMP present on this page?',
        e
      );
    }
  };

  const handleLinkAccountButtonClick = () => {
    trackAlexaTCEvent('agree and link account');

    const { state, redirect_uri } = paramsToObject(new URLSearchParams(window.location.search));

    return redirectWithParams(
      acceptConsentUrl[getEnv()],
      `client_id=${
        clientId[getEnv()]
      }&redirect_uri=${redirect_uri}&state=${state}&response_type=code&scope=offline_access+acs_cpn&audience=https://api.news.co.uk/broadcast-alexa`
    );
  };

  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const acsCookie = new ACSCookie(cookieName);
    const cpn = acsCookie.getParam(cpnParam);

    try {
      const linking = JSON.parse(getQuery('linking')) || false;
      const amazonCode = getQuery('code');
      const brand = getQuery('brand');
      const error = getQuery('error');
      const isApp = getQuery('type') === 'app';
      const environment = getEnv();

      const URLs = isApp ? APP_URLS : WEB_URLS;

      if (linking) {
        setShowLoader(true);

        if (error) {
          // on amazon auth error or cancel
          const state = getQuery('state');

          if (state) {
            try {
              const { extraInfo }: { extraInfo: string } = JSON.parse(atob(state));

              if (extraInfo) {
                const decodedExtraInfo = atob(extraInfo); // expected extraInfo to be 'redirectTo=<url>'
                const redirectTo = decodedExtraInfo.split('=')[1];

                if (redirectTo) return redirectWithParams(redirectTo);
              }
            } catch (error) {
              return redirectWithParams(URLs[brand][environment], 'alexaLinkingCompleted=false');
            }
          }

          return redirectWithParams(URLs[brand][environment], 'alexaLinkingCompleted=false');
        }

        amazonCode ? redirectToAuthorize(amazonCode) : fetchAmazonLoginUrl();
      } else {
        addConsentCookie(cpn);
        initSPConsent();
      }
    } catch (e) {
      setError(true);
    }
  }, []);

  useEffect(() => {
    if (tealiumScriptHasBeenLoaded) {
      trackAlexaTCPage();
    }
  }, [tealiumScriptHasBeenLoaded]);

  return (
    <div className="alexa-page">
      <AlexaHeader />

      <section id="site" className="alexa-site">
        <main className="main-alexa">
          <div id="title_wrapper">
            {error && (
              <>
                <h1 id="generic_error_title" className="greeting">
                  {texts.error_try_again}
                </h1>
              </>
            )}
          </div>

          {!error && (
            <>
              <div id="message_wrapper">
                <div id="confirm_account_access_message">
                  <p id="paragraph_heading">{texts.confirm_account_access}</p>
                  <p id="list_heading">{texts.confirm_happy_for}</p>
                  <ul>
                    <li>{texts.alexa_account_access}</li>
                    <li>{texts.alexa_view_activity}</li>
                    <li>
                      {texts.privacy_policy_description}
                      <a
                        href="https://newsprivacy.co.uk/"
                        target="_blank"
                        className="bold"
                        rel="noreferrer"
                      >
                        {texts.privacy_policy}
                      </a>
                      {texts.change_privacy_preferences}
                      <a
                        id="edit_preferences_btn"
                        href="#"
                        className="bold"
                        onClick={handleChangePreferencesButtonClick}
                      >
                        here
                      </a>
                      .
                    </li>
                    <li>{texts.unlink_alexa_app}</li>
                  </ul>
                </div>
              </div>
            </>
          )}

          <div id="btn_wrapper">
            {error ? (
              <>
                <div id="close_btn_wrapper">
                  <button
                    id="close_btn"
                    className="btn-alexa btn-alexa-primary"
                    type="button"
                    onClick={() => {
                      handleAccountLinkCancelButtonClick('close');
                    }}
                  >
                    {texts.close}
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="bottom_space" id="agree_btn_wrapper">
                  <button
                    id="agree_btn"
                    className="btn-alexa btn-alexa-primary"
                    type="button"
                    onClick={handleLinkAccountButtonClick}
                  >
                    {texts.agree_link_account}
                  </button>
                </div>
                <div id="cancel_btn_wrapper">
                  <a
                    id="cancel_btn_alexa"
                    href="#"
                    onClick={() => {
                      handleAccountLinkCancelButtonClick('cancel');
                    }}
                  >
                    {texts.cancel}
                  </a>
                </div>
              </>
            )}
          </div>
        </main>
      </section>

      {showLoader && <Loader />}
    </div>
  );
};

export default AlexaPage;

import { getEnvVar } from './getEnv';

const JOIN_URL = getEnvVar('API_PATH');

export type Config = {
  joinURL: string;
};

export const config: Config = {
  joinURL: JOIN_URL
};

import { useEffect, useState } from 'react';
import { SOFT_STOP_SEEN_COOKIE, SSO_COOKIE, emailVerificationPageMessages } from '../../constants';
import { getCookie } from '../../helpers/getCookie';
import {
  fetchResendEmail,
  fetchUser,
  fetchUserVerificationStatus
} from '../../helpers/fetcher/fetcher';
import { saveItemToLocalStorage } from '../../helpers/email-verification/saveItemToLocalStorage';
import { User, Verification } from '../../types';
import { gracePeriodExpired } from '../../helpers/email-verification/gracePeriodExpired';
import { getEnv } from '../../helpers/getEnv';
import { VerificationStatus } from '../../enums';
import { redirectToBrand } from '../../helpers/redirect/redirectToBrand';
import { isValidEmail } from '../../helpers/isValidEmail';
import './EmailVerificationPage.scss';
import { GenericError } from '../../components/GenericError/GenericError';
import { SideText } from '../../components/SideText/SideText';
import {
  trackCheckYourInboxPageView,
  trackEmailVerificationHardStopPageView,
  trackEmailVerificationSoftStopPageView,
  trackOkGotItButtonClick
} from '../../helpers/analyticsHelpers';
import { useAppContext } from '../../AppProvider';

const EmailVerificationPage = () => {
  const { tealiumScriptHasBeenLoaded } = useAppContext();
  const [showErrorPage, setShowErrorPage] = useState(false);
  const [showSoftStopPage, setShowSoftStopPage] = useState(false); //soft stop page
  const [showHardStopPage, setShowHardStopPage] = useState(false); //hard stop page
  const [userData, setUserData] = useState<User>({} as User);
  const [userStatus, setUserStatus] = useState<Verification>({} as Verification);
  const [userEmail, setUserEmail] = useState('');
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [showInputError, setShowInputError] = useState(false);
  const [userUpdatedEmail, setUserUpdatedEmail] = useState(false); //email sent page
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [ssoCookieValue, setSSOCookieValue] = useState('');
  const [cpnValue, setCpnValue] = useState('');
  const [hidePage, setHidePage] = useState(true);

  //to be changed to FF if we decide to do https://nidigitalsolutions.jira.com/browse/WWP-4331
  const disableEmailVerification = (): boolean => {
    const currentEnv = getEnv();
    if (currentEnv === 'dev') {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    const ssoCookie = getCookie(SSO_COOKIE);
    if (ssoCookie) {
      setSSOCookieValue(ssoCookie);
    } else {
      setHidePage(false);
      setShowErrorPage(true);
    }

    const cpnValue = getCookie(SSO_COOKIE)?.split('eid=')[1]?.split('&e=')[0];
    if (cpnValue) {
      setCpnValue(cpnValue);
    } else {
      setHidePage(false);
      setShowErrorPage(true);
    }

    if (ssoCookie && cpnValue) {
      const getUserData = async () => {
        try {
          const data = await fetchUser(cpnValue, ssoCookie);
          if (Object.keys(data).length === 0) {
            setHidePage(false);
            setShowErrorPage(true);
          } else {
            setUserData(data);
            setUserEmail(data.email);
            saveItemToLocalStorage('user', data);
          }
        } catch (error: any) {
          setHidePage(false);
          setShowErrorPage(true);
        }
      };
      getUserData();
    }
  }, []);

  useEffect(() => {
    if (ssoCookieValue && cpnValue && userData) {
      const getUserVerificationStatus = async () => {
        try {
          const status = await fetchUserVerificationStatus(
            cpnValue,
            ssoCookieValue,
            userData.email
          );

          setUserStatus(status);
          saveItemToLocalStorage('status', status);
        } catch (error: any) {
          setHidePage(false);
          setShowErrorPage(true);
        }
      };
      getUserVerificationStatus();
    }
  }, [cpnValue, ssoCookieValue, userData]);

  useEffect(() => {
    const env = getEnv();
    const isPreProdEnv = env !== 'prod' ? true : false;
    if (ssoCookieValue && cpnValue && userData && userStatus) {
      const reachedGracePeriod = gracePeriodExpired(userStatus.createdTime, isPreProdEnv);

      if (
        userStatus.verificationStatus === VerificationStatus.PENDING &&
        !disableEmailVerification()
      ) {
        fetchResendEmail(
          cpnValue,
          ssoCookieValue,
          userEmail,
          userData.firstName,
          userData.lastName
        );
        setHidePage(false);
        saveItemToLocalStorage('reachedGracePeriod', reachedGracePeriod);
        setShowSoftStopPage(true);
      }
      if (
        userStatus.verificationStatus === VerificationStatus.NOT_VERIFIED &&
        !reachedGracePeriod &&
        !disableEmailVerification()
      ) {
        setHidePage(false);
        const date = new Date();
        const expiryDate = date.setDate(date.getDate() + 1);
        //set cookie so user doesn't see this page for 24h
        document.cookie = `${SOFT_STOP_SEEN_COOKIE}=eid=${cpnValue};expires=${expiryDate}`;
        saveItemToLocalStorage('reachedGracePeriod', reachedGracePeriod);
        setShowSoftStopPage(true);
      }

      if (
        userStatus.verificationStatus === VerificationStatus.NOT_VERIFIED &&
        reachedGracePeriod &&
        !disableEmailVerification()
      ) {
        setHidePage(false);
        saveItemToLocalStorage('reachedGracePeriod', reachedGracePeriod);
        setShowHardStopPage(true);
      }

      if (
        disableEmailVerification() ||
        userStatus.verificationStatus === VerificationStatus.VERIFIED
      ) {
        redirectToBrand();
      }
    }
  }, [cpnValue, ssoCookieValue, userData, userEmail, userStatus]);

  useEffect(() => {
    if (tealiumScriptHasBeenLoaded) {
      if (userUpdatedEmail) {
        trackCheckYourInboxPageView();
      }

      if (showHardStopPage) {
        trackEmailVerificationHardStopPageView();
      }

      if (showSoftStopPage) {
        trackEmailVerificationSoftStopPageView();
      }
    }
  }, [showHardStopPage, showSoftStopPage, tealiumScriptHasBeenLoaded, userUpdatedEmail]);

  const handleGotItBtn = () => {
    if (showSoftStopPage && tealiumScriptHasBeenLoaded) {
      trackOkGotItButtonClick(cpnValue, false);
      redirectToBrand();
    }
    if (userUpdatedEmail && tealiumScriptHasBeenLoaded) {
      trackOkGotItButtonClick(cpnValue, true);
      redirectToBrand();
    }
  };

  const handleResendEmailBtn = async () => {
    const resendEmail = await fetchResendEmail(
      cpnValue,
      ssoCookieValue,
      userEmail,
      userData.firstName,
      userData.lastName
    );
    if (resendEmail.status === 'SENT') {
      setUserUpdatedEmail(true);
      setShowHardStopPage(false);
      setShowSoftStopPage(false);
    }
  };

  const handleEditEmailBtn = (e: any) => {
    e.preventDefault();
    setIsEditingEmail(true);
    setEmailIsValid(false);
  };

  const handleCancelBtn = (e: any) => {
    e.preventDefault();
    setIsEditingEmail(false);
  };

  const handleCloseBtn = () => {
    redirectToBrand();
  };

  const handleEmailInputChange = (e: any) => {
    e.preventDefault();
    setInputValue(e.target.value);
  };

  const handleSaveEmail = async (e: any) => {
    e.preventDefault();

    if (isValidEmail(inputValue)) {
      setEmailIsValid(true);
    }

    if (inputValue === userEmail || !isValidEmail(inputValue)) {
      setShowInputError(true);
    } else {
      setUserEmail(inputValue);
      setIsEditingEmail(false);
      setInputValue('');

      try {
        const resendEmail = await fetchResendEmail(
          cpnValue,
          ssoCookieValue,
          userEmail,
          userData.firstName,
          userData.lastName
        );

        if (resendEmail && resendEmail.status === 'SENT') {
          setUserUpdatedEmail(true);
          setShowHardStopPage(false);
          setShowSoftStopPage(false);
        }
      } catch (error: any) {
        redirectToBrand();
      }
    }
  };

  const handleRetry = (e: any) => {
    e.preventDefault();
    setShowInputError(false);
    setEmailIsValid(false);
  };

  return (
    <>
      {showHardStopPage || showSoftStopPage || userUpdatedEmail ? (
        <div className={hidePage ? 'hidden' : 'email-verification-app'}>
          <div className="background"></div>
          <div className="contentWrapper">
            {showSoftStopPage && (
              <div className="close_button_wrapper">
                <button className="close_button" onClick={handleCloseBtn}>
                  X
                </button>
              </div>
            )}
            <div className="form-section">
              <div className="form-wrapper">
                <header className="ev-header">
                  <section className="banner"></section>
                </header>
                <section className="section">
                  <main
                    className={
                      showHardStopPage
                        ? `main_hardStop`
                        : userUpdatedEmail
                          ? `main_emailSent`
                          : `email-verification-main`
                    }
                  >
                    <div className="main_title_wrapper">
                      <h1 id="you_are_signed_up_title" className="greeting">
                        {showSoftStopPage &&
                          !userUpdatedEmail &&
                          emailVerificationPageMessages.signedUpHeader}
                        {showHardStopPage &&
                          !userUpdatedEmail &&
                          emailVerificationPageMessages.verifyEmailHeader}
                        {userUpdatedEmail && emailVerificationPageMessages.checkInboxHeader}
                      </h1>
                    </div>

                    <div className="main_message_wrapper">
                      <p id="thanks_for_registering_message" className="paragraph">
                        {showSoftStopPage &&
                          !userUpdatedEmail &&
                          emailVerificationPageMessages.thanksForRegisteringMessage}{' '}
                        {showHardStopPage &&
                          !userUpdatedEmail &&
                          emailVerificationPageMessages.verifyEmailMessage}{' '}
                        {userUpdatedEmail && emailVerificationPageMessages.inboxMessage}{' '}
                        <span id="thanks_message_email_address">{userEmail}</span>.{' '}
                        {userUpdatedEmail && emailVerificationPageMessages.selectLinkMessage}
                      </p>
                    </div>

                    <div className="grace_period_wrapper">
                      <p id="grace_period" className="paragraph">
                        {(showSoftStopPage || userUpdatedEmail) &&
                          emailVerificationPageMessages.softStopMessage}
                        {showHardStopPage && emailVerificationPageMessages.hardStopMessage}
                      </p>
                    </div>

                    {(showSoftStopPage || userUpdatedEmail) && (
                      <div className="main_btn_wrapper">
                        <div id="got_it_btn_wrapper">
                          <button
                            id="got_it_btn"
                            className="btn btn-primary"
                            onClick={handleGotItBtn}
                          >
                            Ok, got it
                          </button>
                        </div>
                      </div>
                    )}

                    {(showSoftStopPage || showHardStopPage) && (
                      <>
                        <div
                          className={
                            showHardStopPage ? `resend_wrapper_hardStop` : `resend_wrapper`
                          }
                        >
                          <h4 className="section_title">
                            {emailVerificationPageMessages.cantFindEmail}
                          </h4>

                          <div className="flex-resendEmail">
                            <label>{emailVerificationPageMessages.checkSpam}</label>
                            <button
                              id="resend_btn"
                              className="btn btn-link"
                              onClick={handleResendEmailBtn}
                            >
                              Resend email
                            </button>
                          </div>
                        </div>
                        <div
                          id={
                            showSoftStopPage
                              ? `change_email_wrapper`
                              : `change_email_wrapper_hardStop`
                          }
                        >
                          <h4 className="section_title">
                            {emailVerificationPageMessages.notCorrectEmail}
                          </h4>
                          <label>{emailVerificationPageMessages.updateYourAccountDetails}</label>

                          <form id="change_email_form">
                            <div className="flex">
                              <div id="input_wrapper">
                                <input
                                  id="change_email_input"
                                  className={
                                    isEditingEmail && showInputError
                                      ? `change_email_input invalid`
                                      : emailIsValid
                                        ? `change_email_input valid`
                                        : ''
                                  }
                                  type="email"
                                  name="change_email_input"
                                  disabled={!isEditingEmail}
                                  placeholder={isEditingEmail ? '' : userEmail}
                                  value={isEditingEmail ? inputValue : userEmail}
                                  onChange={handleEmailInputChange}
                                />
                              </div>

                              <button
                                id="edit_email_btn"
                                className={!isEditingEmail ? `btn btn-secondary-email` : `hidden`}
                                onClick={handleEditEmailBtn}
                              >
                                <span>Edit</span>
                                <svg
                                  fill="#0072ee"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="10px"
                                  height="10px"
                                >
                                  <path
                                    fill="#0072ee"
                                    d="M14.5 5.5L3 17 3 21 7 21 18.5 9.5zM21.707 4.879l-2.586-2.586c-.391-.391-1.024-.391-1.414 0L16 4l4 4 1.707-1.707C22.098 5.902 22.098 5.269 21.707 4.879z"
                                  />
                                </svg>
                              </button>
                              <button
                                id="cancel_email_btn"
                                className={
                                  isEditingEmail ? `btn btn-secondary-cancel-email` : `hidden`
                                }
                                onClick={handleCancelBtn}
                              >
                                Cancel
                              </button>
                            </div>
                            <div
                              id="change_email_server_error_wrapper"
                              className={showInputError ? `flex-error` : `hidden`}
                            >
                              <label id="change_email_server_error_message">
                                An error occurred, please try again.
                              </label>
                              <div className="error_vl"></div>
                              <button
                                id="change_email_server_error_retry_button"
                                className="btn btn-secondary-error"
                                type="button"
                                onClick={handleRetry}
                              >
                                Retry
                              </button>
                            </div>
                            <button
                              id="change_email_btn"
                              className={
                                isEditingEmail
                                  ? `btn btn-border`
                                  : showInputError
                                    ? `hidden`
                                    : `hidden`
                              }
                              type="submit"
                              onClick={handleSaveEmail}
                            >
                              Save and continue
                            </button>
                          </form>
                        </div>
                      </>
                    )}
                  </main>
                </section>
              </div>
            </div>

            <SideText />
          </div>
        </div>
      ) : (
        showErrorPage && <GenericError />
      )}
    </>
  );
};

export default EmailVerificationPage;

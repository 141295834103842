import App from './App';
import { LandingPage } from './pages/landing-page/LandingPage';
import EmailVerificationPage from './pages/email-verification/EmailVerificationPage';
import AlexaPage from './pages/alexa/AlexaPage';
import LinkingResultPage from './pages/linking-result/LinkingResultPage';
import ProfilePage from './pages/profile/ProfilePage';
import { ErrorPage } from './pages/error-page/ErrorPage';

const routes = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: 'verify/:token',
        element: <LandingPage />
      },
      {
        path: 'alexa',
        element: <AlexaPage />
      },
      {
        path: 'linking-result',
        element: <LinkingResultPage />
      },
      {
        path: 'verification',
        element: <EmailVerificationPage />
      },
      {
        path: 'profile',
        element: <ProfilePage />
      },
      {
        path: 'error',
        element: <ErrorPage />
      }
    ]
  }
];

export default routes;

import { consentTriggerApiScript, consentTriggerScript, consentUniScript } from '../constants';
import { appendHeadScripts } from './appendHeadScripts';

export const addConsentCookie = (cpn: string) => {
  if (cpn) {
    document.cookie = `authId=${cpn}; path=/`;
  } else {
    console.warn('There is NO cpn number, the SP AC will not work as expected.');
  }
};

export const buildConsentConfigScript = () => {
  return `window._sp_queue = [];window._sp_ = {config: {propertyHref: 'https://alexa',baseEndpoint: 'https://cdn.privacy-mgmt.com',accountId: 259,propertyId: 30126,gdpr: {},ccpa: {},targetingParams:{isAlexa: true},events: {onConsentReady: function onConsentReady(_messageType, consentUUID, consent) {window.dispatchEvent(new CustomEvent('consentReady', {detail: {_messageType, consent}}));}}}};`;
};

export const initSPConsent = () => {
  const consentConfigScript = buildConsentConfigScript();

  appendHeadScripts([
    consentTriggerApiScript,
    consentTriggerScript,
    consentConfigScript,
    { src: consentUniScript }
  ]);
};

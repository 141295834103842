import { SSO_COOKIE } from '../../constants';
import { EmailResponse, ProfileResponse, User } from '../../types';
import { Verification } from '../../types';
import { VerificationError } from '../../types';
import { config } from '../getConfig';

const JOIN_URL = config.joinURL;

const fetchVerifyUser = async (token: string): Promise<Verification | VerificationError> => {
  let userVerificationInfo = {} as Verification | VerificationError;

  try {
    const response = await fetch(`${JOIN_URL}/v1/verify`, {
      method: 'PUT',
      body: JSON.stringify({ token: token }),
      headers: { 'Content-Type': 'application/json' }
    });

    userVerificationInfo = await response.json();
  } catch (error: any) {
    console.log('fetchVerifyUser: ', error?.message);
    return userVerificationInfo;
  }
  return userVerificationInfo;
};

const fetchUser = async (cpn: string, ssoCookieValue: string): Promise<User> => {
  let user = {} as User;

  if (!cpn && !ssoCookieValue) return user;

  const fetchUserURL = `${JOIN_URL}/v1/user/${cpn}`;

  try {
    const response = await fetch(fetchUserURL, {
      method: 'GET',
      headers: { 'X-Cookie': `${SSO_COOKIE}=${encodeURIComponent(ssoCookieValue)}` }
    });

    const userDetails = await response.json();
    user = {
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      cpn: userDetails.cpn,
      email: userDetails.email,
      responseStatus: response.status
    };
  } catch (error: any) {
    console.log('fetchUser: ', error?.message);

    return user;
  }

  return user;
};

const fetchUserVerificationStatus = async (
  cpn: string,
  ssoCookieValue: string,
  email: string
): Promise<Verification> => {
  let verificationStatus = {} as Verification;

  if (!cpn && !ssoCookieValue && !email) return verificationStatus;

  try {
    const response = await fetch(`${JOIN_URL}/v1/main/status/${cpn}`, {
      method: 'POST',
      headers: {
        'X-Cookie': `${SSO_COOKIE}=${encodeURIComponent(ssoCookieValue)}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email })
    });

    verificationStatus = await response.json();
  } catch (error: any) {
    console.log('fetchUserVerificationStatus: ', error?.message);
    return verificationStatus;
  }

  return verificationStatus;
};

const fetchResendEmail = async (
  cpn: string,
  ssoCookieValue: string,
  email: string,
  firstName: string,
  lastName: string
): Promise<EmailResponse> => {
  let resendEmailResponse = {} as EmailResponse;

  try {
    const response = await fetch(`${JOIN_URL}/v1/main/email/${cpn}`, {
      method: 'POST',
      headers: {
        'Cookie': `${SSO_COOKIE}=${encodeURIComponent(ssoCookieValue)}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        firstName: firstName,
        lastName: lastName
      })
    });

    resendEmailResponse = await response.json();
  } catch (error: any) {
    console.log('fetchResendEmail: ', error?.message);
    return resendEmailResponse;
  }

  return resendEmailResponse;
};

const fetchProfile = async (
  ssoCookieValue: string,
  cpn: string,
  gender: string,
  yob: string
): Promise<ProfileResponse> => {
  let profileResponse = {} as ProfileResponse;

  try {
    const response = await fetch(`${JOIN_URL}/v1/profile/${cpn}`, {
      method: 'PUT',
      headers: {
        'Cookie': `${SSO_COOKIE}=${encodeURIComponent(ssoCookieValue)}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        gender: gender,
        yob: yob
      })
    });

    profileResponse = await response.json();
  } catch (error: any) {
    console.log('fetchProfile: ', error?.message);
    return profileResponse;
  }

  return profileResponse;
};

export { fetchVerifyUser, fetchUser, fetchUserVerificationStatus, fetchResendEmail, fetchProfile };

import { PRE_PROD_GRACE_PERIOD_IN_MIN, PROD_GRACE_PERIOD_IN_MIN } from '../../constants';

export const gracePeriodExpired = (createdTime: string, isPreProdEnv: boolean): boolean => {
  const gracePeriod = isPreProdEnv ? PRE_PROD_GRACE_PERIOD_IN_MIN : PROD_GRACE_PERIOD_IN_MIN;
  const gracePeriodInMs = gracePeriod * 60 * 1000;

  const createdTimeinMs = Date.parse(createdTime);
  const currentDate = +new Date();

  const timeDifference = currentDate - createdTimeinMs;

  return timeDifference >= gracePeriodInMs;
};

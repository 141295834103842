import { createContext, useContext, useState } from 'react';

type AppProviderProps = {
  children: React.ReactNode;
};

type AppContextProps = {
  tealiumScriptHasBeenLoaded: boolean;
  setTealiumScriptHasBeenLoaded: (data: boolean) => void;
};

export const AppContext = createContext<AppContextProps>({
  tealiumScriptHasBeenLoaded: false,
  setTealiumScriptHasBeenLoaded: () => {}
});

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [tealiumScriptHasBeenLoaded, setTealiumScriptHasBeenLoaded] = useState(false);

  const value = {
    tealiumScriptHasBeenLoaded,
    setTealiumScriptHasBeenLoaded
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);
